<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar" style="padding-left: 20px">
          <el-form  >
            工程编号/名称:
            <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.projectStr"></el-input>
            <span style="padding-left:20px;">第三方施工队：</span>
            <el-input
                style="width: 200px"
                placeholder="请输入"
                size="small"
                v-model="searchBox.teamName"
            ></el-input>
            <span style="padding-left:20px;">工程类型：</span>
            <el-select v-model="searchBox.projectType" clearable placeholder="请选择" size="small">
              <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <span style="padding-left:20px;">工程状态：</span>
            <el-select v-model="searchBox.projectStatus" clearable placeholder="请选择" size="small">
              <el-option
                  v-for="item in statustypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <span style="padding-left:20px;">现场管理员：</span>
            <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.siteManager"></el-input>
            <div style="margin-top: 10px">
              <span style="padding-left:25px;">提交时间：</span>
              <el-date-picker
                  size="small"
                  v-model="time"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  start-placeholder="选择开始时间"
                  end-placeholder="选择结束时间">
              </el-date-picker>

              <el-button type="primary" class="SearchBtn" size="small" @click="search">查询</el-button>
              <el-button type="primary" class="SearchBtn" size="small" @click="resetData">重置</el-button>
            </div>
          </el-form>
        </div>
        <div class="table">

          <!--数据表格-->
          <el-table
              ref="tableData"
              :data="tableData"
              style="width: 100%;margin: 15px 0;"
              border
              class="table-height-s2"
              :stripe="true"
              :default-sort = "{prop: 'createTime', order: 'descending'}">
            <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
            <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectType" label="工程类型" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectSite" label="工程地点" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="teamName" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip width="100"></el-table-column>
            <el-table-column prop="progressApplyRealname" label="提交人" align="center" show-overflow-tooltip width="100"></el-table-column>
            <el-table-column prop="progressApplyTime" label="提交时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="progressApproveRealname" label="审核人" align="center" show-overflow-tooltip width="100"></el-table-column>
            <el-table-column prop="progressApproveTime" label="审核时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" align="left"  width="200">
              <template slot-scope="{row}">
                <el-button type="primary" size="small" @click="seeDetil(row,1)">查看本次</el-button>
                <el-button type="primary" size="small" @click="seeAllDetil(row,2)">查看全部</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
                :current-page="searchBox.current"
                :background="true"
                :page-sizes="[ 30, 50, 100]"
                :page-size="searchBox.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="本次施工进度详情"
        :visible.sync="detilDialog"
        width="45%"
        :close-on-click-modal="false"
    >
      <div style="height: 600px;overflow: auto">
        <p class="detil-code">
          施工进度信息
        </p>
        <div style="padding: 10px">
          <div style="display: flex;line-height: 50px">
            <div style="flex: 1;">工程名称：{{project.projectName}}</div>
            <div style="flex: 1;">现场管理员：{{project.siteManager}}</div>
          </div>
          <div>第三方施工队：{{project.teamName}}</div>
          <div style="display: flex;line-height: 50px">
            <div style="flex: 1;">提交人：{{project.progressApplyRealname}}</div>
            <div style="flex: 1;">提交时间：{{project.progressApplyTime}}</div>
          </div>
        </div>
        <el-tabs v-model="activeName"    style="margin-bottom: 20px"  @tab-click="handleClick">
          <el-tab-pane label="当前进度" name="1">
            <div style="display: flex">
              <div> 进度图片：</div>
              <div>
                <el-image
                    style="width: 100px; height: 100px"
                    :src="project.progressImageUrl[0]"
                    :preview-src-list="project.progressImageUrl">
                </el-image>
                <span style="margin-left:10px">共{{project.progressImageUrl.length}}张</span>
              </div>
            </div>
            <div style="margin-top: 20px">
              进度说明：{{project.progressDescription}}
            </div>
          </el-tab-pane>
          <el-tab-pane label="工程量" name="2">
            <div v-if="projectObj.length>0">
              <el-table :data="projectObj" style="width: 100%;margin: 15px 0;" border>
                <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
                <el-table-column prop="unitName" label="物料名称"  align="center"> </el-table-column>
                <el-table-column prop="unitVal" label="数量"  align="center"> </el-table-column>
                <el-table-column prop="unitMeasurement" label="单位"  align="center"> </el-table-column>
              </el-table>
            </div>
            <div v-else style="text-align: center">
              暂无工程量信息
            </div>
          </el-tab-pane>
        </el-tabs>
        <p class="detil-code">
          审核信息
        </p>
        <div style="padding: 10px">
          <div style="line-height: 30px">审核结果：{{project.progressStatus}}</div>
          <div style="line-height: 30px" v-if="project.progressStatus=='已审核驳回'">驳回原因：{{project.progressApproveDescription}}</div>
          <div style="line-height: 30px">审核人：{{project.progressApproveRealname}}</div>
          <div style="line-height: 30px">审核时间：{{project.progressApproveTime}}</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="所有施工进度详情"
        :visible.sync="tableDialog"
        width="45%"
        :close-on-click-modal="false">
      <el-table
          ref="detiltableData"
          :data="detiltableData"
          style="width: 100%;margin: 15px 0;"
          border>
        <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
        <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="teamName" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="progressApplyRealname" label="提交人" align="center" show-overflow-tooltip width="100"></el-table-column>
        <el-table-column prop="progressApplyTime" label="提交时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="left"  width="200">
          <template slot-scope="{row}">
            <el-button type="primary" size="small" @click="seeDetil(row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination
            :current-page="detilsearchBox.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="detilsearchBox.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="detiltotal"
            @size-change="detilhandleSizeChange"
            @current-change="detilhandleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchProgressLatestList,
  searchProgressLatestUnitList,
  searchProgressLatestDetail,
  projectStatus,
  searchProgressLatestAll
} from "@/RequestPort/report/projectReport"
import {getDict} from "../../apis/commonType";
export default {
  name: "lockout",
  data(){
    return{
      activeName:'1',
      project:{
        progressImageUrl:[]
      },
      projectObj:[],
      detilsearchBox:{
        current:1,
        size: 100,
      },
      searchBox:{
        current:1,
        size: 100,
        name:'',
        projectStr:'',
        thirdTeam:'',
        projectType:'',
        projectStatus:'',
      },
      time:[],
      total:null,
      detiltotal:null,
      detilDialog:false,
      tableDialog:false,
      typeList:[],
      statustypeList:[],
      tableData:[],
      detiltableData:[],
      progressId:'',
      progressType:'',
    }
  },
  mounted() {
    //字典查工程类型
    getDict({parentCode:'2100'}).then(res=>{
      this.typeList = res.data
    })
    //查状态
    projectStatus().then(res=>{
      this.statustypeList = res.data
    })
    let date=new Date()
    let year = date.getFullYear();
    let month  = date.getMonth()+1;
    let day  = date.getDate();
    let t1 = year+'-'+month+'-'+day+' '+'00:00:00'
    let t2 = year+'-'+month+'-'+day+' '+'23:59:59'
    this.time.push(t1)
    this.time.push(t2)
    if(this.time!=null&&this.time.length>0){
      this.searchBox.startTime = this.time[0]
      this.searchBox.endTime = this.time[1]
    }
    this.loadList(this.searchBox);
  },
  methods:{
    handleClick(tab){
      if(tab.index==1){
        searchProgressLatestUnitList({progressId:this.progressId}).then(res=>{
          this.projectObj = res.data
        })
      }
    },
    seeAllDetil(row){
      this.progressId = row.id
      this.projectNumber = row.projectNumber
      this.teamName = row.teamName
      this.tableDialog = true
      //查询table的接口
      this.loadDetilTable(this.detilsearchBox)
    },
    seeDetil(row){
      this.progressId = row.id
      this.detilDialog = true
      this.activeName = '1'
      searchProgressLatestDetail({progressId:this.progressId}).then(res=>{
        this.project = res.data
        this.project.progressImageUrl = res.data.progressImageUrl.split(',')
      })
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList(this.searchBox)
    },
    loadDetilTable(obj){
      obj.projectNumber = this.projectNumber
      obj.teamName = this.teamName
      //
      searchProgressLatestAll(obj).then(res=>{
          this.detiltableData = res.data.records
          this.detiltotal = res.data.total
      })
    },
    detilhandleSizeChange(val){
      this.detilsearchBox.current = 1
      this.detilsearchBox.size = val
      //请求
      this.loadDetilTable(this.detilsearchBox)
    },
    detilhandleCurrentChange(val){
      this.detilsearchBox.current = val
      this.loadDetilTable(this.detilsearchBox)
    },
    search(){
      if(this.time!=null&&this.time.length>0){
        this.searchBox.startTime = this.time[0]
        this.searchBox.endTime = this.time[1]
      }else {
        this.searchBox.startTime= ''
        this.searchBox.endTime = ''
      }
      this.searchBox.current = 1
      this.loadList(this.searchBox)
    },
    loadList(obj){
      searchProgressLatestList(obj).then(res=>{
        if(res.code===200){
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    resetData() {
      this.searchBox={
        current:1,
        size: 100,
        name:'',
        projectStr:'',
        thirdTeam:'',
        projectType:'',
        projectStatus:'',
        siteManager:''
      }
      this.time = []
      this.loadList(this.searchBox)
    }
  }
}
</script>

<style scoped>
.requireTitle:before{
  content:"*";
  color:red;
  font-weight:bold;
  position: relative;
  top: 2px;
}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 0px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
</style>